const UIkit = window.UIkit;

export default function () {
    UIkit.util.on(document, 'change', '.sortorder-form select', function (e) {

        const elem = e.target,
            form = elem.closest('form');

        form.submit();
    });

    UIkit.util.on(document, 'change', '.perpage-form select', function (e) {

        const elem = e.target,
            form = elem.closest('form');

        form.submit();
    });

    addEventListener("mouseover", (e) => {
        const elem = e.target,
            parentElem = elem.parentElement;

        if (!parentElem?.classList.contains('product-colors'))
            return;

        if (elem.classList.contains('active'))
            return;

        elem.classList.add('active');

        const prodElem = elem.closest('.product-preview'),
            imageWrapperElem = prodElem.getElementsByClassName('product-image')[0],
            imageElem = imageWrapperElem.getElementsByTagName('img')[0];

        imageElem.dataset.origImage = imageElem.src;
        imageElem.src = elem.dataset.image;
    });

    addEventListener("mouseout", (e) => {
        const elem = e.target,
            parentElem = elem.parentElement;

        if (!parentElem?.classList.contains('product-colors'))
            return;

        if (!elem.classList.contains('active'))
            return;

        elem.classList.remove('active');

        const prodElem = elem.closest('.product-preview'),
            imageWrapperElem = prodElem.getElementsByClassName('product-image')[0],
            imageElem = imageWrapperElem.getElementsByTagName('img')[0];

        imageElem.src = imageElem.dataset.origImage;
    });
}