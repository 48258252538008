const UIkit = window.UIkit;
import axios from "axios";

export default function () {
    UIkit.util.on(document, 'beforeshow', '#main-navigation .uk-navbar-dropdown', function (e) {

        const elem = e.target,
            id = elem.dataset.id,
            type = elem.dataset.type,
            dropdowngrid = document.querySelector('#main-navigation [data-id="' + elem.dataset.id + '"] .uk-navbar-dropdown-grid'),
            translations = window.CbxTranslations,
            expandWithChildren = elem.dataset.expand == 'true',
            itemChildLimit = elem.dataset.expandLimit ?? 5;

        const chunkArray = (array, chunkSize) => Array(Math.ceil(array.length / chunkSize))
                .fill()
                .map((_, index) => index * chunkSize)
                .map(begin => array.slice(begin, begin + chunkSize));

        axios.get('/contextapi/' + window.CbxApiContextKey + '/v1/' + (type === 'Content' ? 'content' : 'productcategories') + '/children/' + id + (expandWithChildren ? '?expandWithChildren=true' : ''))
            .then(function (response) {

                let html = '';

                const limit = 80;
                let showMore = false;
                if (response.data.children.length > limit)
                {
                    showMore = true;
                    response.data.children = response.data.children.splice(0, limit);
                }

                if (type === "Content")
                {
                    html += `
                    <div>
                        <ul class="uk-nav uk-navbar-dropdown-nav">`;

                    response.data.children.forEach(item => {
                        const active = false;
                        html += `
                            <li class="${(active ? 'uk-active' : '')}">
                                <a href="${item.link}">${item.title}</a>
                            </li>
                        `;
                    });

                    html += `
                        </ul>
                    </div>`;
                }
                else
                {
                    // group children into 4 columns
                    let colSize = Math.ceil(response.data.children.length / 4);
                    if (expandWithChildren) colSize = 1;
                    const chunks = chunkArray(response.data.children, colSize);

                    chunks.forEach(chunk => {
                        html += `<div>`;
                        chunk.forEach(item => {
                            const active = false;

                            let children = ``;
                            let count = 0;
                            if (item.children?.length)
                            {
                                children += `<ul class="uk-nav uk-navbar-dropdown-nav">`;
                                item.children.every(child => {
                                    children += `
                                    <li>
                                        <a data-img-src="${child.imgsrc}" class="uk-text-small uk-padding-remove uk-text-inherit category-button" href="${child.link}">${child.title}</a>
                                    </li>`;

                                    count++;

                                    if (count == itemChildLimit)
                                    {
                                        children += `
                                        <li>
                                            <a class="uk-text-small uk-padding-remove uk-text-inherit uk-text-bold" href="${item.link}">${translations?.actions?.showMore ?? 'Show more'}</a>
                                        </li>`;

                                        return false;
                                    }

                                    return true;
                                });
                                children += `</ul>`;
                            }

                            html += `
                            <div>
                                <ul class="uk-nav uk-navbar-dropdown-nav">
                                <li data-img-src="${item.imgsrc}" class="category-button ${(active ? 'uk-active' : '')}">
                                    ${expandWithChildren ? `<strong>` : ``}<a href="${item.link}">${item.title}</a>${expandWithChildren ? `</strong>` : ``}
                                    ${children}
                                </li>
                                </ul>
                            </div>
                            `;
                        });
                        html += `</div>`;
                    });

                }

                if (showMore)
                html += `<div class="uk-width-1 uk-text-center"><a class="uk-button uk-button-default" href="${response.data.parent.link}">${translations?.actions?.showMore ?? 'Show more'} <span uk-icon="icon: arrow-right"></span></a></div>`;

                dropdowngrid.innerHTML = html;

                // Creating image element
                var imgDiv = dropdowngrid.parentElement.lastChild;
                imgDiv.innerHTML += `<img id="category-image-preview-${elem.dataset.id}">`
                var imgElement = document.getElementById(`category-image-preview-${elem.dataset.id}`);

                var buttons = document.getElementsByClassName("category-button");

                for (var i = 0; i < buttons.length; i++){
                    buttons[i].addEventListener("mouseenter", (e) => {
                        var src = e.target.getAttribute("data-img-src");
                        imgElement.src = src;
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    });
}